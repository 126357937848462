import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import RowIcon from "../images/icon_rowview.svg"
import GridIcon from "../images/icon_tileview.svg"
import Card from "../components/ui/listing/card"
import Postlist from "../components/ui/listing/postlist"
import Frame from "../components/frame"
import { resizeAllItems } from "../../static/masonry"

// this function depends on the graphql query being the way it is

function mapToComponent(group, component) {

    let out = new Array();

    if (!group) {
        return <p>No posts :(</p>
    }

    group.forEach((nodes) => {

        // the query is structured to provide all the blog pages 
        // grouped by directory. To turn them into components, we 
        // do some tests...

        if (nodes.nodes[0].relativeDirectory === "") {

            // for single pages that aren't part of a series/group
            // e.g. every page in the root directory

            nodes.nodes.map((node) => {

                const thisDate = node.childMdx.frontmatter.date 
                    ? Date.parse(node.childMdx.frontmatter.date) 
                    : null;

                const newComponent = component({
                    title: node.childMdx.frontmatter.title ? 
                        node.childMdx.frontmatter.title : `This node (${node.name}) doesn't have a title field`,
                    date: thisDate,
                    slug: node.childMdx.fields.slug ? 
                        node.childMdx.fields.slug : node.name,
                    description: node.childMdx.frontmatter.description ?
                        node.childMdx.frontmatter.description : null,
                })

                // append an object which groups the listing 
                // with its associated date (we have to do this
                // for sorting purposes)
                out.push({
                    date: thisDate, 
                    component: newComponent
                });
            })
        } else {

            // stuff for page groups

            // get metadata

            let metadata = {};

            try {
                metadata = require('../content/blog/' + nodes.nodes[0].relativeDirectory + '/metadata.json');
            } catch (e) {
                console.log(`Warning: metadata.json not found in ${nodes.nodes[0].dir}`);
                console.log(`you'll find out soon enough...`);
                console.log(e);
                metadata = {};
            }

            // find the most recent time for all the pages 
            // in the group. 
            // start recentDate with UNIX time zero
            let recentDate = Date.parse('01 Jan 1970 00:00:00 GMT');

            // generate an array of titleslug objects for 
            // each of the posts in the group. 
            //
            // [ {title: "blah blah", slug: "/blog/blah"},
            //   {...},
            //   ...    
            // ]
            const titleslugs = nodes.nodes.map((node) => {

                const thisDate = Date.parse(node.childMdx.frontmatter.date);

                if (thisDate > recentDate) {
                    recentDate = thisDate;
                }

                return {
                    title: node.childMdx.frontmatter.title ?
                        node.childMdx.frontmatter.title : `this node (${node.name}) doesn't have an MDX child :(`,
                    slug: node.childMdx.fields.slug ?
                        node.childMdx.fields.slug : node.name
                }
            })

            const newComponent = component({
                title: metadata.title ? metadata.title : `Update your metadata.json file in ${nodes.nodes[0].dir} to include a 'title' field`,
                date: recentDate,
                description: metadata.description ? metadata.description : `Update your metadata.json file in ${nodes.nodes[0].dir} to include a 'description' field`,
                titleslugs: titleslugs,
            });

            // push the same type of object as in the 
            // logic for single pages (see above)
            out.push({
                date: recentDate, 
                component: newComponent
            });
        }
    
    })

    // sort by date...
    out.sort((a,b) => a.date < b.date ? 1 : -1);

    // and then return just the list of 
    // components
    return out.map(unit => unit.component);
}


export default (props) => {

    /*

    this is useful if you want to see every page in the site

    const allpaths = props.data.allSitePage.nodes.map((node) => {
        return <Link to={node.path} style={{'display': 'block'}}>{node.path}</Link>
    })
    */

    // types: 'card', 'postlist'

    const [ dimensions, setDimensions ] = useState([0,0]);

    useEffect(() => {
        resizeAllItems();
    }, []);

    const [currentView, setView] = useState('postlist');

    const x = mapToComponent(props.data.allFile.group, Postlist);

    const renderListing = () => {
        if (currentView === 'card') {
            return (
                <div className='masonry-grid'>
                    {mapToComponent(props.data.allFile.group, Card)}
                </div>)
        } else  {
            if (currentView !== 'postlist') {
                console.error(`currentView is ${currentView} (which is not valid), so the render function defaulted to postlist view`);
            }
            return (
                <div>
                    {mapToComponent(props.data.allFile.group, Postlist)}
                </div>
            )
        }
    }

    const toggleView = () => {
        if (currentView === 'card')
            setView('postlist');
        else {
            setView('card');
        }
    }

    const togglebutton = <button onClick={toggleView}>Toggle view</button>

    const viewbuttoms = (
        <div style={{opacity: "0.15", display: "inline-block"}}>
            <img style={{padding: "3px", margin: "0 3px", color: "burgundy", border: "1px solid black", borderRadius: "5px",   width: "25px"}} src={RowIcon}/>
            <img style={{padding: "3px", margin: "0 3px", color: "burgundy", border: "1px solid black", borderRadius: "5px",   width: "25px"}} src={GridIcon}/>
        </div>
    )

    return (
        <Frame>
            {renderListing()}
        </Frame>
    )
}

/*
export const pageQuery = graphql`
query allpaths {
    allSitePage(sort: {fields: path}) {
        nodes {
            path
        }
    }
}
`*/
export const pageQuery = graphql`
query mq {
    allFile(filter: {extension: {regex: "/mdx?$/"}}, sort: {fields: name}) {
        group(field: relativeDirectory) {
            nodes {
                relativeDirectory
                dir
                name
                childMdx {
                    frontmatter {
                        title
                        description
                        date
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    }
    allSitePage(sort: {fields: path}) {
        nodes {
            path
        }
    }
}
`