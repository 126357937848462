const gridClassName = "masonry-grid";
const itemClassName = "masonry-item";
const contentClassName = "masonry-content";

function resizeItem(item) {
    var grid = document.getElementsByClassName(gridClassName)[0];
    
    const gridstyle = window.getComputedStyle(grid);

    var rowheight = parseInt(gridstyle.getPropertyValue('grid-auto-rows'));
    var rowgap = parseInt(gridstyle.getPropertyValue('row-gap'));
    var rowspan = Math.ceil(
        (item.querySelector(`.${contentClassName}`).getBoundingClientRect().height + rowgap) / (rowheight + rowgap)
    );

    item.style.gridRowEnd = `span ${rowspan}`;
    
}

function resizeAllItems() {
    var all = document.getElementsByClassName(itemClassName);
    for (var i = 0; i < all.length; i++) {
        resizeItem(all[i]);
    }
    //console.log("resized all item");
}

exports.resizeAllItems = resizeAllItems;