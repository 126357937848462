import React from "react"
import cardStyle from "./style/card.module.css"
import commonListStyle from "./style/common.module.css"
import { Link } from "gatsby"
import LinkHover from "./helpder/linkHover"
import { toCanonical } from "../../../../static/datetime"
import linkHover from "./helpder/linkHover"

export default (props) => {

    const hasChildPage = props.slug != null;

    const isSeries = props.titleslugs != null;

    const readlink = props.slug ? <Link to={props.slug} className={`${commonListStyle.link} title-med`}>Read it</Link> : null;

    const titlebody = props.slug ? (
        <Link className={commonListStyle.titleaslink} style={{verticalAlign: 'middle'}} to={props.slug}>
			 {props.title}</Link>
    ) : props.title;

    const title = (
        <h2 className={`${commonListStyle.title} title-med text-sans-serif-display`}>
            {titlebody}
        </h2>
    )

    const linklist = props.titleslugs ? 
        props.titleslugs.map((ts,idx) => {
            return [(
                linkHover(<Link to={ts.slug} className={`${commonListStyle.link} title-med text-sans-serif-display`}>
                    <span className="text-mono" style={{color: '#999', fontSize: '0.7em', paddingRight: '5px'}}>{idx} </span>
                    {ts.title}
                </Link>)
            ),<br/>]
        }) : null;
    
    const dateString = isSeries ? `Latest on ${toCanonical(props.date)}` : toCanonical(props.date);

    const inner = (
        <div className={`${cardStyle.spacer}`}>
            {props.slug ? LinkHover(title) : title}
            {<p className={`${commonListStyle.date} text-sans-serif-display`}>{dateString}</p>}
            <p className={` ${commonListStyle.description} text-serif`}>{props.description}</p>
            <div className={`${commonListStyle.linkbox}`}>
                {linklist}
            </div>
        </div>
    );


    return (
        <div className={`
            ${cardStyle.container} 
            ${commonListStyle.container} 
            ${hasChildPage ? cardStyle.hoverbox : null}
            masonry-item`}>
            <div className={`masonry-content ${cardStyle.content}`}>
                {inner}
            </div>
            
        </div>
    )
}
