import React from "react"
import commonListStyle from "../style/common.module.css"

export default (children) => {
    return (
        <div className={commonListStyle.hoverbox} style={{display: "inline-block"}}>
            {children}
            <div className={`${commonListStyle.hrulebox}`}>
                <div className={`${commonListStyle.hrule}`}/>
            </div>
        </div>
    )
}