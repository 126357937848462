import React from "react"
import { Link } from "gatsby"
import commonListStyle from "./style/common.module.css"
import postListStyle from "./style/postlist.module.css"
import { toCanonical } from "../../../../static/datetime"
import linkHover from "./helpder/linkHover"

/*

This expects an array of title slugs in the order you want them displayed...

*/

export default (props) => {

    const hasChildPage = props.slug != null;

    const isSeries = props.titleslugs != null;

    const hrule = hasChildPage ? (
            <div className={`${postListStyle.hrulebox}`}>
                <div className={`${postListStyle.hrule}`}></div>
            </div>
    ) : null;

    const titlebody = props.slug ? (
        <Link className={commonListStyle.titleaslink} to={props.slug}>{props.title}</Link>
    ) : props.title;

    const title = (
        <h2 className={`${commonListStyle.title} title-med text-sans-serif-display`}>
            {titlebody}
        </h2>
    )

    const linklist = props.titleslugs ? 
    props.titleslugs.map(ts => {
        return [(
            linkHover(<Link to={ts.slug} className={`${commonListStyle.link} title-med text-sans-serif-display`}>{ts.title}</Link>)
        ),<br/>]
    }) : null;

    const dateString = toCanonical(props.date);

    const inner = (
        <div>
            {props.slug ? linkHover(title) : title}
            <p className={`${commonListStyle.date} ${postListStyle.date}`}>{isSeries ? 'Latest on ' + dateString : dateString}</p>
            <p className={`${postListStyle.description} ${commonListStyle.description} text-serif`}>{props.description}</p>
            <div className={`${postListStyle.linkcontainer} ${postListStyle.indentleft}`} style={{display: 'inline-block'}}>
                {linklist}
            </div>
        </div>    
    );

    const encase = (hasChildPage)
        ? <Link to={props.slug} className={`${commonListStyle.readlink}`}>{inner}</Link>
        : inner;

    return (
        <div className={`${postListStyle.container} text-sans-serif-display`}>
            {encase}
        </div>
    );

}
